import React from "react";
import { useTranslation } from "react-i18next";

import { observer } from "mobx-react";

export const LanguageSwitcher = observer(({ isOnNavbar, isStatic , isTransparent}) => {
  let { i18n } = useTranslation();
  const Switcher = (
    <div className={isOnNavbar?'ml-4':''}>
      <button
        className={'px-1 uppercase text-sm outline-none focus:outline-none '}
        style={i18n.resolvedLanguage === "en" ? {color: '#ff5f90'} : {color: '#000000'}}
        type="button"
        onClick={() => {
          i18n.changeLanguage("en-US");
        }}
      >
        <h6>EN</h6>
      </button>
      <span className="text-on-surface text-sm" style={{color:"#28282B"}}>|</span>
      <button
        className={'px-1 uppercase text-sm outline-none focus:outline-none'}
        style={i18n.resolvedLanguage === "nl" ? {color: '#ff5f90'} : {color: '#000000'}}
        type="button"
        onClick={() => {
          i18n.changeLanguage("nl-NL");
        }}
      >
        <h6>NL</h6>
      </button>
    </div>
  );
        
  if (isStatic)
    return <div className={`${isTransparent? '': 'bg-background'}`}>{Switcher}</div>;

  return Switcher;
});
