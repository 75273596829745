import React from "react";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { string, object } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { apiClient } from "../../api";
import { withTranslation } from "react-i18next";
import { ForgotPasswordForm3PAppWrapper } from "./ForgotPassword3PApp";
import {

  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

class LoginForm extends React.Component {
  state = {
    showForgotPassword: false,
    showPassword:false,
  };
  toggleForgotPassword = () => {
    this.setState({ showForgotPassword: !this.state.showForgotPassword });
  };
  getErrorMessage(status) {
    if (status === "EMAIL_PASSWORD_ERROR")
      return `Login failed: please check your email${
        this.props.email ? ` and password` : ``
      }`;

    if (status === "SERVER_ERROR") {
      return "Oops something went wrong, please try again in few mins!";
    }
  }
  authContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "85vh",
    padding: "2rem",
    maxWidth: "600px",
  };

  // inputStyle = {
  //   backgroundColor: "#FFFFFF",
  //   border: "none",
  //   borderRadius: "20px",
  //   marginBottom: "0.5rem",
  //   padding: "12px",
  //   width: "100%",
  //   color: "#000",
  // };

  inputDisabledStyle = {
    backgroundColor: "#DCDCDC",
  };

  errorMessageStyle = {
    color: "rgb(255, 95, 144)",
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isValid,
      dirty,
      isSubmitting,
      t,
    } = this.props;
    if (this.state.showForgotPassword) {
      return (
        <ForgotPasswordForm3PAppWrapper
          email={values.email}
          showLogin={this.toggleForgotPassword}
          auth={this.props.authApp}
        />
      );
    }

    
    return (
      <div id="auth-container" style={this.authContainerStyle}>
        <div id="auth-header">
          {this.props.authApp.appInfo.logo ? (
            <img
            id="auth-app-logo"
              alt="app-logo"
              className="w-8 h-8"
              src={`${
                this.props.authApp.appInfo.logo?.indexOf("data:image") === -1
                  ? "data:image/jpeg;base64,"
                  : ""
              }${this.props.authApp.appInfo.logo}`}
              // style={{ width: "100%", maxWidth: "400px", height: "auto" }}
            />
          ) : null}
          <h2 id="auth-header-heading">{this.props.authApp.appInfo.name}</h2>
        </div>
        <form className="flex flex-col justify-between" onSubmit={handleSubmit}>
          <div>
            <div>
              <input
               className="auth-app-input-field cursor-not-allowed"
                id="email"
                name="email"
                type="text"
                placeholder={t("Email")}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                disabled={!!this.props.disableEmail}
                value={values.email}
                data-testid="email-input"
                style={this.inputDisabledStyle}
              />
             
              {errors.email && touched.email ? (
                <small style={{ color: "rgb(255, 95, 144)" }}>
                  {t(errors.email)}
                </small>
              ) : null}
            </div>
            {this.props.email || this.props.auth.isUnderBubl ? (
              <div>
                <div className="relative" style={{ marginTop: "0.5rem" }}>
                <input
                  className="auth-app-input-field"
                  id="password"
                  type={this.state.showPassword?"text":"password"}
                  placeholder={t("Password")}
                  name="password"
                  onChange={handleChange}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  value={values.password}
                  data-testid="password-input"
                  style={this.inputStyle}
                />
                 <div
                  className="absolute inset-y-0 right-0 flex items-center px-5"
                  onClick={() => {
                    this.setState({ showPassword: !this.state.showPassword });
                  }}
                >
                  {this.state.showPassword ? (
                    <FontAwesomeIcon icon={faEye} color="black"/>
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash}  color="black" />
                  )}
                </div>
              
                </div>
                {errors.password && touched.password ? (
                  <small className="auth-app-input-error" style={{ color: "rgb(255, 95, 144)" }}>
                    {t(errors.password)}
                  </small>
                ) : null}
              </div>
            ) : null}
            {this.props.email || this.props.auth.isUnderBubl ? (
              <div className="auth-form-row mt-6">
                <button
                  type="button"
                  data-testid="forgot-password-button"
                  aria-label="Forgot Password?"
                  className="hover:text-primary"
                  style={{ color: "#28282B" }}
                  onClick={this.toggleForgotPassword}
                >
                  {t("Forgot password?")}
                </button>
              </div>
            ) : null}
            <div className="mt-6">
              <small
                id="auth-app-powered-by"
                className="textColors"
                style={{
                  alignItems: "baseline",
                  justifyContent: "flex-start",
                  color: "#28282B",
                }}
              >
                {t("Powered by")}
                <img
                  src="/logo-bubl.png"
                  alt="logo"
                  width={120}
                  style={{ marginLeft: ".5rem" }}
                />
              </small>
              <small style={{ color: "#28282B" }}>{t("Data protection")}</small>
            </div>
          </div>
          <div></div>
          <div id="auth-submit-wrapper" className="mt-10">
            <button
              id="auth-app-submit"
              data-testid="login-button"
              aria-label="Submit Login"
              disabled={!(isSubmitting || (dirty && isValid))}
              className={`${
                !(isValid && dirty) ? "auth-app-submit-disabled" : ""
              } `}
              type="submit"
            >
              {t("Login")}
              {isSubmitting ? (
                <FontAwesomeIcon
                  id="auth-app-btn-spinner"
                  icon={faSpinner}
                  spin
                />
              ) : null}
            </button>
          </div>
        </form>
        <div></div>
      </div>
    );
  }
}

const schema = object().shape({
  email: string()
    .required("Email is required")
    .email("Valid Email is required"),
  password: string().required("Password is required"),
});

const LoginFormWithTs = withTranslation()(LoginForm);
export const LoginViewFormWrapper = withRouter(
  withFormik({
    mapPropsToValues: (props) => {
      return { email: props.email ?? "", password: "" };
    },
    validationSchema: (props) => {
      return schema;
    },
    handleSubmit: (
      values,
      { setSubmitting, props, setFieldError, setStatus, resetForm }
    ) => {
      let promise;
      promise = apiClient(`co-nect/v1/login`, {
        method: "POST",
        body: {
          email: values.email,
          password: values.password,
          serviceId: props.authApp.currentAppId,
        },
      })
        .then((res) => {
          console.log(res, props, props.auth, props.authApp);
          // props.auth.setUser({token: data.accessToken})
          return props.auth.preLogin(props.skipPreCheck || false);
        })
        .then(() => {
          console.log("In close view");
          props.authApp.closeView();
        });

      promise.catch(({ data, response }) => {
        toast.error(data.error);
        if (
          response &&
          (response.status === 404 ||
            response.status === 401 ||
            response.status === 400)
        ){
          setStatus("EMAIL_PASSWORD_ERROR");
        
        }else{
          setStatus("SERVER_ERROR");
        }

        setSubmitting(false);
      });
    },

    displayName: "LoginForm",
  })(LoginFormWithTs)
);
