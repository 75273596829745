import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { styleInject } from "../../common/helper";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useStores } from "../../hooks/useStore";
import { LanguageSwitcher } from "../shared/LanguageSwitcher";
import { LoginViewFormWrapper } from "./LoginView";

export const AppLogin = observer((props) => {
  const params = useParams();
  const {t} = useTranslation()
  const queryParams = useQueryParams();
  const { auth, authApp } = useStores();
  useEffect(() => {
    console.log(params.appId,queryParams.get('redirect') );
    authApp.setCurrentApp(params.appId, decodeURI(queryParams.get('redirect') ?? ""), decodeURI(queryParams.get('redirectPath') ?? ""));
  // eslint-disable-next-line
  }, []);
  const themeCss = authApp?.appInfo?.loginThemeCss
  useLayoutEffect(()=>{
    styleInject(themeCss, {id:'appTheme'})
  },[themeCss])

  const mainContainerStyle = {
    backgroundColor: "#e9e9e9",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  };

  const mainHeaderStyle = {
    alignItems: 'center',
    //backgroundColor: '#bfbfbf',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: '0.5rem',
    position: 'absolute',
    top: '1rem',
    width: '100%',
    zIndex: 2000,
    color:"#28282B"
  };  

  const mainContentStyle = {
    paddingTop: "3rem",
    display: "flex",
    justifyContent: "center"
  };

  const pinkBorderStyle = {
    borderTop: "1px solid #ff5f90",
    bottom: "1rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    opacity: "1",
    position: "relative",
    width: "100%"
  };

  return (
    <div id="auth-page-container" data-testid="pre-loader" style={mainContainerStyle}>
      <div style={mainHeaderStyle}>
        <LanguageSwitcher isStatic={true} isTransparent={true} />
        <h1 style={{fontSize: 24, fontWeight: "900", color: "#000",paddingLeft: 50}}>Log in</h1>
        <div></div>
      </div>
      <div id="main-content" style={mainContentStyle}>
        {authApp?.status === "loading" ? (
          <FontAwesomeIcon id="loader-spinner" data-testid="message-icon" icon={faSpinner} spin />
        ) : null}
        {authApp?.status === "loaded" ? (
          <LoginViewFormWrapper
            auth={auth}
            email={decodeURIComponent(queryParams?.get("email") ?? "")}
            authApp={authApp}
            skipPreCheck={true}
            disableEmail={true}
          />
        ) : null}
        {["redirection_failed","failedToLoadApp"].includes(authApp?.status) ? (
          <p id="error-text">ERR: {t(authApp.error)}</p>
        ) : null}
      </div>
      <hr className="hr-wrapper" style={pinkBorderStyle}></hr>
    </div>
  );
});
